body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

img {
  border-radius: '5px';
}

.container {
  display: flex;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  background: rgb(243, 241, 241); 
}

.main {
  background: #fff; 
  display:flex; 
  flex-direction: column;
  justify-content: flex-start;
  height:100%;
  width: 100%;
  padding-left:20px;
  padding-right:20px;
  padding-bottom: 60px;
  padding-top:20px; 
  min-height: calc(100vh - 50px);
}

.header {
  background: rgb(0, 120, 212);
  color: white;
  height: 48px;
  display:flex;  
}

.header-content {
  flex:7;
  margin-left:20px;  
  padding-top:auto;
  padding-bottom:auto;
  width:30%;
}

.header-content h1 {
  font-size: 16px;
}

.footer {
  padding:5px;
  height:100%;
  padding: 20px;
  background-color: rgb(251, 214, 104);
}

.footer a {
  text-decoration: none;
  color:#000;
}

.hero-feature {
  color:'#7b7c77';
  font-weight:'lighter';  
}

.defaultText {
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 14px;
  font-weight: 400;
  color: rgb(50, 49, 48);
  display: inline;
}

.blog-post {
  width:auto;
  margin-right: 10px;
  margin-bottom: 60px;  
  text-align: justify;
}

.blog-post-img {
  float:left;
  padding-right:20px;
  padding-bottom:20px;
  max-width: 200px;
}